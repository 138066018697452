import { PageSection } from "../PageSection";
import React, { ReactNode } from "react";

const PageSectionSplit = ({
  left,
  right,
}: {
  left: ReactNode;
  right: ReactNode;
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-fr">
    <div className="flex">{left}</div>
    <div className="flex">{right}</div>
  </div>
);

export { PageSectionSplit };
