import { PageSection } from "../PageSection";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactNode } from "react";

const PageColourfulSection = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <PageSection>
    <div className={`relative ${className}`}>
      <div className="absolute top-0 right-0 left-0 bottom-0 -z-30 animate-jumbotron bg-jumbotron"></div>
      <StaticImage
        className="!absolute top-0 right-0 left-0 bottom-0 -z-10"
        src="./Header-bg.png"
        alt=""
      ></StaticImage>
      <div>{children}</div>
    </div>
  </PageSection>
);

export { PageColourfulSection };
