import { PageLayout } from "../components/PageLayout";
import { PageParticleSectionJumbotron } from "../components/PageParticleSectionJumbotron";
import { PageSectionContent } from "../components/PageSectionContent";
import { PageSectionSplit } from "../components/PageSectionSplit";
import { SectionCallToAction } from "../components/SectionCallToAction";
import { SiteLink } from "../components/SiteLink";
import React from "react";

const IndexPage = () => {
  return (
    <PageLayout transparentHeader>
      <PageParticleSectionJumbotron>Contact Us</PageParticleSectionJumbotron>
      <PageSectionSplit
        left={
          <div className="w-full flex justify-center items-center">
            <PageSectionContent heading="Contact Details">
              <table className="text-xl">
                <tbody>
                  <tr>
                    <td className="pr-4 text-right">Telephone</td>
                    <td>
                      <SiteLink href="tel:+442034683353">
                        (+44) 020 3468 3353
                      </SiteLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-4 text-right">Email</td>
                    <td>
                      <SiteLink href="mailto:hello@gosystem.co.uk">
                        hello@gosystem.co.uk
                      </SiteLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-4 text-right">Offices</td>
                    <td>London, Surrey, Sheffield</td>
                  </tr>
                </tbody>
              </table>
            </PageSectionContent>
          </div>
        }
        right={
          <iframe
            className="h-[40rem] w-full border-none"
            src="https://form.typeform.com/to/MVkwDNyW?typeform- medium=embed-sdk&typeform-embed=embed-widget&typeform-source=gosystem.co.uk&typeform-embed-id=8fwkc"
          ></iframe>
        }
      />
      <SectionCallToAction buttonLabel="Support centre" to="/support-help-faq">
        Looking for support or incident response?
      </SectionCallToAction>
    </PageLayout>
  );
};

export default IndexPage;
