import { PageColourfulSection } from "../PageColourfulSection";
import { PageContainer } from "../PageContainer";
import { PageSectionHeading } from "../PageSectionHeading";
import { SiteButton, SiteButtonType } from "../SiteButton";
import React, { ReactNode } from "react";
import Fade from "react-reveal/Fade";

const SectionCallToAction = ({
  children,
  buttonLabel = "Contact Us",
  to = "/contact",
}: {
  children: ReactNode;
  buttonLabel?: string;
  to?: string;
}) => (
  <PageColourfulSection>
    <PageContainer>
      <Fade>
        <div className="grid md:grid-cols-jumbotron gap-4 items-center py-24 text-white">
          <PageSectionHeading className="flex-grow">
            {children}
          </PageSectionHeading>
          <SiteButton type={SiteButtonType.INVERTED} to={to}>
            {buttonLabel}
          </SiteButton>
        </div>
      </Fade>
    </PageContainer>
  </PageColourfulSection>
);

export { SectionCallToAction };
